import React from 'react'
import styles from '../SubSection/SubSection.module.scss';
import { useTranslation } from "react-i18next";

const SubSection = ({title, description}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.subSectionContainer}>
      <h3 className={styles.title}>{t(title)}</h3>
      <p className={styles.description}>{t(description)}</p>
    </div>
  )
}

export default SubSection

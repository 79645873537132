import React, { useState, useEffect } from "react";
import styles from "./HeaderHome.module.scss";
import { ReactComponent as Share } from "../../../assets/share-link.svg";
// import { ReactComponent as Close } from "../../../assets/close.svg";
import Close from "../Close/Close";
import { connect } from "react-redux";
import { endSearchHandler } from "../../../store/generalActions";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const HeaderHome = ({ handleHowItWorks, isSearching, endSearchHandler, showAlertModal }) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [isCopy, isCopyHandler] = useState(false);

  const copyToClipboard = () => {
    let tempInput = document.createElement("input");
    tempInput.value = window.location.host;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    isCopyHandler(true);
    setTimeout(() => {
      isCopyHandler(false);
    }, 2000);
  };

  const isSearchingHandler = isSearching => {
    const isSearchingComp = (
      <figure className={!isMobile ? `${styles.howitworks} ${styles.white}` : styles.howitworks}>
        <div onClick={endSearchHandler}>
          <Close className={!isMobile ? `${styles.howitworks} ${styles.white}` : styles.howitworks} color={!isMobile ? "#fff" : "var(--buttonsSec)"} />
        </div>
      </figure>
    );

    const isNotSearching = (
      <>
        <figure className={styles.howitworks} onClick={handleHowItWorks}>
          <span>?</span>
        </figure>
        <div className={styles.share} onClick={copyToClipboard}>
          <Share />
          <h3>{t("Compartir")}</h3>
        </div>{" "}
      </>
    );
    return isSearching ? isSearchingComp : isNotSearching;
  };

  return (
    <>
      <header className={`${styles.headerhome} ${showAlertModal && isMobile ? styles.alertOpen : ""} ${isSearching ? styles.searching : ""} `}>{isSearchingHandler(isSearching)}</header>
      <div className={`${styles.copy} ${isCopy ? styles.open : ""}`}>
        <span>Link copiado</span>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    isSearching: state.isSearching
  };
};

export default connect(mapStateToProps, { endSearchHandler })(HeaderHome);

import React from "react";
import styles from "./FinishSurveyModal.module.scss";
import Lottie from "react-lottie";
import checkMarkLottie from "../../assets/check-mark-success.json";
import { connect } from "react-redux";
import { showCongratsModal } from "../../store/generalActions";
import { useTranslation } from "react-i18next";
const defaultOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const FinishSurveyModal = ({ opened, showCongratsModal }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className={`${styles.container} ${opened ? styles.active : ""}`} onClick={showCongratsModal} />
      <div className={`${styles.modal} ${opened ? styles.active : ""}`}>
        <Lottie options={{ animationData: checkMarkLottie, ...defaultOptions }} width={100} height={100} />
        <h3 className={styles.title}>{t('¡Gracias por colaborar!')}</h3>
        <p className={styles.description}>{t('Tu ayuda es fundamental para que los demás puedan encontrar productos.')}</p>
        <button className={styles.btn} onClick={showCongratsModal}>
          {t('Finalizar')}
        </button>
      </div>
    </>
  );
};

export default connect(null, { showCongratsModal })(FinishSurveyModal);

import React, { useState, useEffect, useRef } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import styles from "./Places.module.scss";

const searchOptions = {};

const LocationSearchInput = ({ userLocation, getPlaceFromSearchPDV }) => {
  const [placeId, setPlaceId] = useState(null);
  const [address, setAddress] = useState("");
  const inputEl = useRef(null);

  useEffect(() => {
    // inputEl.current.focus();
  }, []);

  const handleSelect = (address, placeId) => {
    console.log("Place: ", placeId);
    if (placeId) {
      setAddress(address);
      getPlaceFromSearchPDV(placeId, true);
    }
  };

  const onError = (status, clearSuggestions) => {
    console.log("Google Maps API returned error with status: ", status);
    clearSuggestions();
  };

  const suggestionsHandler = suggestions => {
    return suggestions.filter(suggestion => suggestion.types.find(type => type === "supermarket" || type === "pharmacy" || type === "grocery_or_supermarket"));
  };
  return (
    <PlacesAutocomplete value={address} onChange={address => setAddress(address)} onSelect={handleSelect} onError={onError}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            ref={inputEl}
            className={styles.placesInput}
            {...getInputProps({
              placeholder: "Busca por punto de venta"
            })}
          />

          <div className={styles.suggestionContainer}>
            {loading && <div>Loading...</div>}
            {suggestionsHandler(suggestions).map(suggestion => {
              return (
                <div className={styles.suggestion} {...getSuggestionItemProps(suggestion, {})}>
                  <p>{suggestion.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;

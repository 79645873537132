import React from "react";
import styles from "./Footer.module.scss";
import FooterButton from "./FooterButton/FooterButton";
import FooterInput from "./FooterInput/FooterInput";
import { connect } from "react-redux";
import { showSearchModalHandler, showSearchPDVModalHandler } from "../../store/generalActions";
import { ReactComponent as DotRed } from "../../assets/Dot red.svg";
import { ReactComponent as DotGreen } from "../../assets/Dot gren.svg";
import { ReactComponent as DotYell } from "../../assets/Dot yellow.svg";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';

const Footer = ({ showSearchModalHandler, isSearching, showSearchPDVModalHandler, showSearchModal, showSearchPDVModal, isFromSearchPDV }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t, i18n } = useTranslation();
  const contentHandler = isSearching => {
    const isNotSearchingContent = (
      <div className={(showSearchModal || showSearchPDVModal || isFromSearchPDV) && !isMobile ? "" : styles.footer}>
        <div className={styles.footerContainer}>
          <h2>{t('productos-title')}</h2>
          <FooterButton showSearchModalHandler={showSearchModalHandler}>{t('Buscar productos disponibles')}</FooterButton>
          <FooterInput showSearchPDVModalHandler={showSearchPDVModalHandler} />
          <p className={styles.description}>{t('reportar-disponibilidad')}</p>
          <p className={styles.madeItBy}>
            {t('Hecho con')} &#10084; {t('por')}{" "}
            <span>
              <a target='_blank' rel='noopener noreferrer' href='https://paisanos.io'>
                Paisanos.io
              </a>
            </span>
          </p>
        </div>
      </div>
    );
    const isSearchingContent = (
      <div className={styles.result}>
        <div className={styles.resultBox}>
          <h4 className={styles.results}>{t('Resultados')}</h4>
          <p className={styles.resultsDescription}>{t('Seleccioná en el mapa el punto de venta para ver qué productos tiene y saber cómo ir.')}</p>
          <div className={styles.resultContainer}>
            <div className={styles.resultItem}>
              <DotGreen />
              <span>{t('Tiene todos los productos')}</span>
            </div>
            <div className={styles.resultItem}>
              <DotYell />
              <span>{t('Tiene alguno de los productos')}</span>
            </div>
            <div className={styles.resultItem}>
              <DotRed />
              <span>{t('No tiene niguno de los productos')}</span>
            </div>
          </div>{" "}
        </div>
      </div>
    );

    return isSearching ? isSearchingContent : isNotSearchingContent;
  };
  return <footer>{contentHandler(isSearching)}</footer>;
};

const mapStateToProps = state => {
  return {
    isSearching: state.isSearching,
    isFromSearchPDV: state.isFromSearchPDV,
    showSearchModal: state.showSearchModal,
    showSearchPDVModal: state.showSearchPDVModal
  };
};

export default connect(mapStateToProps, {
  showSearchModalHandler,
  showSearchPDVModalHandler
})(Footer);

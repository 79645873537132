import React from "react";
import styles from "./Loading.module.scss";
import Lottie from "react-lottie";
import LoadingLottie from "../../../assets/loadingLottie.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Loading = () => {
  return (
    <div className={styles.loading}>
      <Lottie options={{ animationData: LoadingLottie, ...defaultOptions }} width={100} height={100} />
    </div>
  );
};

export default Loading;

import * as React from 'react';

const Close = props => {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			stroke={props.color}
			strokeWidth={2}
			strokeLinecap='round'
			strokeLinejoin='round'
			className='prefix__feather prefix__feather-x'
			{...props}
		>
			<path d='M18 6L6 18M6 6l12 12' />
		</svg>
	);
};

export default Close;

import axios from "axios";

const api = "https://api.covid.paisanos.io";
const test = "http://192.168.0.10:5000";
const test2 = "http://test.api.covid.paisanos.io";
const instance = axios.create({
  baseURL: api
});

export default instance;

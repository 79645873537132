import React from "react";
import styles from "./FooterInput.module.scss";
import { ReactComponent as Search } from "../../../assets/search.svg";
import { useTranslation } from "react-i18next";

const FooterInput = ({ showSearchPDVModalHandler }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.footerInput} onClick={showSearchPDVModalHandler}>
      {t("Buscar por punto de venta")}
      <figure>
        <Search />
      </figure>
    </div>
  );
};

export default FooterInput;

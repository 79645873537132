import React from "react";
import styles from "./Alert.module.scss";
import { connect } from "react-redux";
import { showAlertModalHandler } from "../../../store/generalActions";
import Close from "../Close/Close";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const Alert = ({ showAlertModalHandler, theme }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.alert}>
      <div className={styles.close} onClick={showAlertModalHandler}>
        <Close color={"#fff"} />
      </div>
      <h3>{theme && !isMobile ? theme.town : t("home-alert")}</h3>
    </div>
  );
};

export const mapStateToProps = state => {
  return {
    theme: state.theme
  };
};
export default connect(mapStateToProps, { showAlertModalHandler })(Alert);

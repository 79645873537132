import React, { useState, useEffect } from 'react';
import { ReactComponent as Back } from '../../assets/back.svg';
import styles from './SearchProducts.module.scss';
import { connect } from 'react-redux';
import LottieLoading from '../../components/UI/LoadingLottie/LoadingLottie';
import {
	getProducts,
	sendProducts,
	showSearchModalHandler
} from '../../store/generalActions';
import { GAHandler } from '../../utils/AnalyticsHandler';
import { getUserLocation } from '../../store/generalActions';
import { useTranslation } from 'react-i18next';
import SearchCards from './Cards/SearchCards';

const Search = ({
	products,
	userLocation,
	getProducts,
	sendProducts,
	loading,
	showSearchModalHandler
}) => {
	const { t, i18n } = useTranslation();
	const [ selectedProducts, setSelectedProducts ] = useState([]);

	useEffect(() => {
		getProducts();
	}, []);

	const checkProducts = value => {
		GAHandler('Producto', value.name);
		let handleChange;
		if (selectedProducts.find(product => product === value)) {
			handleChange = selectedProducts.filter(element => {
				return element !== value;
			});
			setSelectedProducts(handleChange);
		} else {
			setSelectedProducts(selectedProducts => [ ...selectedProducts, value ]);
		}
	};

	const sendProductsHandler = () => {
		GAHandler('Boton', 'Buscar PDV');
		const sendArray = selectedProducts.map(item => item.id);
		sendProducts(userLocation, sendArray);
	};

	return (
		<div className={styles.providerInfoModal}>
			<div className={styles.modalContainer}>
				<div className={styles.arrow}>
					<Back onClick={showSearchModalHandler} className={styles.back} />
				</div>
				<div>
					<div>
						<h2>{t('¿Qué productos buscas?')}</h2>
						<p>{t('Hacé scroll para ver más productos')}</p>
						<div className={styles.productContainer}>
							<SearchCards
								products={products}
								checkProducts={checkProducts}
								selectedProducts={selectedProducts}
							/>
						</div>
						<button
							onClick={() => {
								if (selectedProducts.length > 0) sendProductsHandler();
							}}
							className={styles.buttonClass}
							style={{ height: loading ? '51px' : '' }}
						>
							{loading ? (
								<LottieLoading
									width={100}
									height={51}
									style={{
										position: 'absolute',
										left: '50%',
										transform: 'translateX(-50%) translateY(-50%)'
									}}
								/>
							) : (
								t('Buscar')
							)}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchTopProps = state => {
	return {
		products: state.products,
		userLocation: state.userLocation,
		loading: state.loading
	};
};

export default connect(mapDispatchTopProps, {
	getProducts,
	sendProducts,
	getUserLocation,
	showSearchModalHandler
})(Search);

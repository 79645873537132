import React from "react";
import styles from "./SearchPDV.module.scss";
import InputPlaces from "./Places/Places";
import { showSearchPDVModalHandler, getPlaceFromSearchPDV } from "../../store/generalActions";
import { ReactComponent as Back } from "../../assets/back.svg";
import { connect } from "react-redux";
const SearchPDV = ({ showSearchPDVModalHandler, userLocation, getPlaceFromSearchPDV }) => {
  return (
    <div className={styles.container}>
      <div className={styles.arrow}>
        <Back onClick={showSearchPDVModalHandler} className={styles.back} />
      </div>
      <InputPlaces userLocation={userLocation} getPlaceFromSearchPDV={getPlaceFromSearchPDV} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userLocation: state.userLocation
  };
};

export default connect(mapStateToProps, { showSearchPDVModalHandler, getPlaceFromSearchPDV })(SearchPDV);

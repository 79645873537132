import React, { useState } from "react";
import styles from "./Chart.module.scss";

const Chart = ({ popularTime }) => {
  const [clicked, clickedHandler] = useState(false);
  const [hour, setHour] = useState(0);
  return (
    <div className={styles.chart}>
      <div className={`${styles.info} ${clicked ? styles.active : ""}`}>{hour}:00hs</div>
      {popularTime.map(item => {
        return (
          <div
            className={styles.chartItem}
            style={{ height: `${item.percent}%` }}
            onClick={() => {
              setHour(item.hour);
              clickedHandler(true);
            }}
            onPointerLeave={() => clickedHandler(false)}
          ></div>
        );
      })}
    </div>
  );
};

export default Chart;

import React from "react";

import Lottie from "react-lottie";
import loadingData from "../../../assets/loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
const LoadingLottie = ({ width, height, style }) => {
  return (
    <>
      <Lottie options={{ animationData: loadingData, ...defaultOptions }} height={height} width={width} style={style} />
    </>
  );
};

export default LoadingLottie;
import React from "react";
import styles from "../SearchProducts.module.scss";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import checkMarkLottie from "../../../assets/checkMarkLottie.json";
const defaultOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const SearchCards = ({ products, checkProducts, selectedProducts }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.productContent}>
      {products
        ? products.map((data, key) => {
            return (
              <div className={styles.product} onClick={() => checkProducts(data)} key={key}>
                <p>{t(data.name)}</p>
                <div className={styles.productCheckContainer}>
                  <div className={selectedProducts.indexOf(data) !== -1 ? `${styles.checked}` : `${styles.oval}`}>{selectedProducts.indexOf(data) !== -1 && <Lottie options={{ animationData: checkMarkLottie, ...defaultOptions }} height={21.4} width={21.4} speed={2} />}</div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default SearchCards;

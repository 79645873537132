import React, { useState, useEffect } from "react";
import { ReactComponent as Back } from "../../assets/back.svg";
import styles from "./ProviderProducts.module.scss";
import Footer from "../Footer/Footer";
import { connect } from "react-redux";
import { getProducts, sendProducts, setUserProducts, showProviderModalHandler, sendProviderProducts, isFromSearchPDVHandler } from "../../store/generalActions";
import { GAHandler } from "../../utils/AnalyticsHandler";
import { getUserLocation } from "../../store/generalActions";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import cancelLottie from "../../assets/cancelLottie.json";
import checkMarkLottie from "../../assets/checkMarkLottie.json";

const defaultOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const ProviderProducts = ({ products, getProducts, sellPointSelected, setUserProducts, showProviderModalHandler, isFromSearchPDV, sendProviderProducts, isFromSearchPDVHandler }) => {
  const { t, i18n } = useTranslation();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [notAvailableProducts, setNotAvailableProducts] = useState([]);
  const [isOpen, isOpenHandler] = useState(false);
  const [place, setPlace] = useState(null);

  useEffect(() => {
    if (!products) getProducts();
  }, []);

  const checkNotAvaibleProducts = value => {
    let handleChange;
    if (selectedProducts.find(product => product.id === value.id)) {
      const filterSelectedProducts = selectedProducts.filter(element => {
        return element.id !== value.id;
      });
      if (notAvailableProducts.find(product => product === value)) {
        handleChange = notAvailableProducts.filter(element => {
          return element !== value;
        });
        setNotAvailableProducts(handleChange);
      } else {
        setNotAvailableProducts(notAvailableProducts => [...notAvailableProducts, value]);
        setSelectedProducts(filterSelectedProducts);
      }
    } else {
      if (notAvailableProducts.find(product => product === value)) {
        handleChange = notAvailableProducts.filter(element => {
          return element !== value;
        });
        setNotAvailableProducts(handleChange);
      } else {
        setNotAvailableProducts(notAvailableProducts => [...notAvailableProducts, value]);
      }
    }
  };

  const checkAvaibleProducts = value => {
    let handleChange;
    if (notAvailableProducts.find(product => product.id === value.id)) {
      const filterNotAvaible = notAvailableProducts.filter(element => {
        return element.id !== value.id;
      });

      if (selectedProducts.find(product => product.id === value.id)) {
        handleChange = selectedProducts.filter(element => {
          return element.id !== value.id;
        });
        setSelectedProducts(handleChange);
      } else {
        setSelectedProducts(selectedProducts => [...selectedProducts, value]);
        setNotAvailableProducts(filterNotAvaible);
      }
    } else {
      if (selectedProducts.find(product => product.id === value.id)) {
        handleChange = selectedProducts.filter(element => {
          return element.id !== value.id;
        });
        setSelectedProducts(handleChange);
      } else {
        setSelectedProducts(selectedProducts => [...selectedProducts, value]);
      }
    }
  };

  const setUserProductsHandler = () => {
    const mapSelected = selectedProducts.map(product => {
      GAHandler("Producto de Encuesta", "Available", product.name);
      return product.id;
    });
    const mapNotAvaible = notAvailableProducts.map(product => {
      GAHandler("Producto de Encuesta", "Not Available", product.name);
      return product.id;
    });

    sendProviderProducts(sellPointSelected.google_maps_id, mapSelected, mapNotAvaible);
    // else setUserProducts(sellPointSelected.id, mapSelected, mapNotAvaible);
  };

  const placeSelected = place => {
    setPlace(place.place_id);
  };

  return (
    <div className={styles.providerInfoModal}>
      <div className={styles.modalContainer}>
        <div className={styles.arrow}>
          <Back
            onClick={() => {
              showProviderModalHandler();
              isFromSearchPDVHandler();
            }}
            className={styles.back}
          />
        </div>
        <div>
          <div>
            <h2>{t("¿Qué productos encontraste?")}</h2>
            <p>{t("Hacé scroll para ver más productos")}</p>
            <div className={styles.productContainer}>
              <div className={styles.productContent}>
                {products
                  ? products.map((data, key) => {
                      return (
                        <div className={styles.product} key={key}>
                          <p>{t(data.name)}</p>
                          <div className={styles.productCheckContainer}>
                            <div onClick={() => checkNotAvaibleProducts(data)} className={notAvailableProducts.indexOf(data) !== -1 ? `${styles.checked}` : `${styles.oval} ${styles.opacityClose}`}>
                              {notAvailableProducts.indexOf(data) !== -1 && <Lottie options={{ animationData: cancelLottie, ...defaultOptions }} height={21.4} width={21.4} speed={2} />}
                            </div>

                            <div onClick={() => checkAvaibleProducts(data)} className={selectedProducts.indexOf(data) !== -1 ? `${styles.checked}` : `${styles.oval} ${styles.opacityTick}`}>
                              {selectedProducts.indexOf(data) !== -1 && <Lottie options={{ animationData: checkMarkLottie, ...defaultOptions }} height={21.4} width={21.4} speed={2} />}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>

            <button
              onClick={() => {
                if (sellPointSelected) setUserProductsHandler();
                else alert("Seleccioná una ubicación valida");
              }}
              className={`${styles.buttonClass} ${styles.yellow}`}>
              {t("Reportar")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchTopProps = state => {
  return {
    products: state.products,
    userLocation: state.userLocation,
    loading: state.loading,
    sellPointSelected: state.sellPointSelected,
    isFromSearchPDV: state.isFromSearchPDV
  };
};

export default connect(mapDispatchTopProps, { getProducts, sendProducts, getUserLocation, setUserProducts, showProviderModalHandler, sendProviderProducts, isFromSearchPDVHandler })(ProviderProducts);
